import { IAverageCost } from '../model/averageCost';
import { ProductCost } from '../model/productCost';
import { IReplacementCost } from '../model/replacementCost';
import { IResult } from '../model/Result';

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/productos/';

export const GetProductCost = async (
  productId: number
): Promise<ProductCost> => {
  const RequestURL: string = BaseURL + productId + '/cost';

  try {
    const Response: ProductCost = await fetch(RequestURL)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProductCost>>;
        }
      })
      .then((data: IResult<ProductCost>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          console.log(body);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetReplacementCost = async (
  productId: number
): Promise<IReplacementCost> => {
  const RequestURL: string = BaseURL + productId + '/ReplacementCost';

  try {
    const Response: IReplacementCost = await fetch(RequestURL)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<IReplacementCost>>;
        }
      })
      .then((data: IResult<IReplacementCost>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          console.log(body);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetAverageCost = async (
  productId: number
): Promise<IAverageCost> => {
  const RequestURL: string = BaseURL + productId + '/AverageCost';

  try {
    const Response: IAverageCost = await fetch(RequestURL)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<IAverageCost>>;
        }
      })
      .then((data: IResult<IAverageCost>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          console.log(body);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteProduct = async (
  ProductId: number,
  token: String
): Promise<boolean> => {
  const RequestURL: string = BaseURL + ProductId;

  try {
    const Response: boolean = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return true;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};
