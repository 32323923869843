import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as OrdenFabricacion from '../stores/OrdenFabricacion';
import { ApplicationState } from '../stores';
import { OdenFabricacionEnCursoCards } from '../components/orden/widgets/OrdenFabricacionEnCursoCards/OrdenFabricacionEnCursoCardComponent';
import { OrdenFabricacionTableList } from '../components/orden/OrdenFabricacionTableList';
import { Orden } from '../model/orden';
import { useState } from 'react';
import moment from 'moment';
import OrderPagination from '../components/Pagination/PaginationComponent';

import 'bootstrap/dist/css/bootstrap.min.css';

export enum _FilterBy {
  LOTE = "LOTE",
  PRODUCT_CODE = "PRODUCT_CODE",
  CUSTOMER_CODE = "CUSTOMER_CODE",
  ORDER_DATE = "ORDER_DATE",
}

export const OrdenesFabricacionPage = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [OrdenFinalizadaFilter, setOrdenFinalizadaFilter] =
    useState<string>('');
  const [OrdenEnCursoFilter, setOrdenEnCursoFilter] = useState<string>('');

  const OrdenState = useSelector((state: ApplicationState) => state.ordenes);
  const [radioSelection, setRadioSelection] = useState<string>(_FilterBy.LOTE); // Estado para manejar la selección de los radio buttons
  const [orderDateChecked, setOrderDateChecked] = useState(false);
  // const [orderDate, setOrderDate] = useState<moment.Moment>(moment());
  const [orderDate, setOrderDate] = useState<string>("");
  const [flagLoteValidation, setFlagLoteValidation] = useState(false);
  const [pressEnterOrButtonSerch, setPressEnterOrButtonSerch] = useState<boolean>(false); //Habilit el  uso de la llamada de servicio para evitar consumir 'getAllOrdenes' innecesariamente;
  const [isFirstEntry, setIsFirstEntry] = useState<boolean>(true); //Habilit el  uso de la llamada de servicio para evitar consumir 'getAllOrdenes' innecesariamente;


  const [pageNumber, setPageNumber] = useState(1); // Estado para la página actual

  React.useEffect(() => {
    dispatch(
      mapDispatchToProps.getAllOrdenes(
        1, 
        20, 
        true, 
        OrdenFinalizadaFilter, 
        radioSelection, 
        orderDateChecked ? moment(orderDate).format("YYYY-MM-DD") : '' 
      )
    );

    dispatch(
      //ProductosStore.getAllProductos(0,100)
      mapDispatchToProps.getAllOrdenes(1, 200, false, '', '', '' )
    );

    
  }, []);

  //Carga nuevamente la lista, al borrarse o limpiarse el campo 'SearchText'.
  React.useEffect(() => {
    if (OrdenFinalizadaFilter == '' || OrdenFinalizadaFilter?.length === 0) {
        searchHandler();
    }
  }, [OrdenFinalizadaFilter]);

  React.useEffect(() => {
    if(isFirstEntry) {
      setIsFirstEntry(false);
      return
    }else{
      searchByPage(pageNumber);
    }

    
  }, [pageNumber]);


  //Buscar solo si posee texto almacenado en 'SearchText'
  React.useEffect(() => {
  if (radioSelection !== '' || radioSelection?.length > 0) {
      searchHandler();
  }

  }, [radioSelection]);

  //Al deshabilitar el switch de la fecha, llama al servicio para que traiga todo.
  React.useEffect(() => {
    if (!orderDateChecked) {
      setOrderDate("");
      searchHandler();
    }
  
  }, [orderDateChecked]);

  

  // Funcion para manejar los cambios en la selección de los radio buttons
  const handleRadioSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelection(e.target.value);
  };
  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrdenFinalizadaFilter(e.target.value);
  };

  // Función para manejar los cambios en la selección del checkbox
  
  const handleOrderDateCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderDateChecked(e.target.checked);
    if(!e.target?.checked){
      setPressEnterOrButtonSerch(true);
    }
  };

  const OrderDateChangeHandler = (event: any) => {
    // setOrderDate(moment(event.target.value));
    setOrderDate(event.target.value);
  };

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber); // Actualizar el estado de la página
  };

  const searchHandler = () => {
    if(!pressEnterOrButtonSerch) return;//Evita que llame al servicio si no se ha presionado Enter o el botón Buscar.

    if (radioSelection === _FilterBy.LOTE && /^[a-zA-Z]+$/.test(OrdenFinalizadaFilter)) {
      setFlagLoteValidation(true);
      return;
    }else{
      setFlagLoteValidation(false);
    }

    dispatch(
      mapDispatchToProps.getAllOrdenes(
        1, 
        20, 
        true, 
        OrdenFinalizadaFilter, 
        radioSelection, 
        orderDateChecked ? moment(orderDate).format("YYYY-MM-DD") : '' 
      )
    );

    dispatch(
      //ProductosStore.getAllProductos(0,100)
      mapDispatchToProps.getAllOrdenes(1, 300, false, '', '', '' )
    );
    // Actualiza el estado de OrdenFinalizadaFilter para desencadenar un nuevo renderizado
    setPressEnterOrButtonSerch(false);
  };


  const searchByPage = (pageNumber: number) => {
    if (radioSelection === _FilterBy.LOTE && /^[a-zA-Z]+$/.test(OrdenFinalizadaFilter)) {
      setFlagLoteValidation(true);
      return;
    }else{
      setFlagLoteValidation(false);
    }

    dispatch(
      mapDispatchToProps.getAllOrdenes(
        pageNumber, 
        20, 
        true, 
        OrdenFinalizadaFilter, 
        radioSelection, 
        orderDateChecked ? moment(orderDate).format("YYYY-MM-DD") : '' 
      )
    );

    dispatch(
      //ProductosStore.getAllProductos(0,100)
      mapDispatchToProps.getAllOrdenes(1, 300, false, '', '', '' )
    );
  };

  const resetFilter = () => {
    setPressEnterOrButtonSerch(true);
    setOrdenFinalizadaFilter('');
    setOrderDateChecked(false);
  }

  // events
  const pressEnterHandler = (e: any) => {
    setPressEnterOrButtonSerch(true);
    return e.key === 'Enter' ? searchHandler() : e;
  };

  // events
  const pressSearchButtonHandler = (e: any) => {
    setPressEnterOrButtonSerch(true);
    return e.type == "click" ? searchHandler() : e;
  };

  // Radio button para manejar el filtrado correctamente.
  const RadioButtons = (
    <div className="">
        <div className="form-check form-check-inline mt-2">
            <input
                type="radio"
                id="lote"
                value="LOTE"
                checked={radioSelection === 'LOTE'}
                onChange={handleRadioSelection}
                className="form-check-input"
            />
            <label htmlFor="lote" className="form-check-label">Lote</label>
        </div>

        <div className="form-check form-check-inline">
            <input
                type="radio"
                id="product_code"
                value="PRODUCT_CODE"
                checked={radioSelection === 'PRODUCT_CODE'}
                onChange={handleRadioSelection}
                className="form-check-input"
            />
            <label htmlFor="product_code" className="form-check-label">Codigo</label>
        </div>
        
        <div className="form-check form-check-inline">
            <input
                type="radio"
                id="customer_code"
                value="CUSTOMER_CODE"
                checked={radioSelection === 'CUSTOMER_CODE'}
                onChange={handleRadioSelection}
                className="form-check-input"
            />
            <label htmlFor="customer_code" className="form-check-label">Cliente</label>
        </div>

        <div className="form-check form-switch form-check-inline">
          <input
            type="checkbox"
            id="order_date"
            checked={orderDateChecked}
            onChange={handleOrderDateCheckboxChange}
            className="form-check-input"
          />
          <label htmlFor="order_date" className="form-check-label">Fecha</label>
        </div>
    </div>
);

  return (
    <>
      <div className="pageHeader">
        <h2>Ordenes de Fabricación</h2>
        <button
          className="btn btn-primary"
          onClick={() => {
            history.push('ordenes/nuevo');
          }}
        >
          Generar nueva orden
        </button>
      </div>
      <label className="SubContentTitle">
        Ordendes de fabricación en curso{' '}
        <input
          type="text"
          id="SearchOrdenInCurse"
          placeholder="Buscar..."
          onChange={(e: any) => setOrdenEnCursoFilter(e.target.value)}
        ></input>
      </label>
      <label className="ContentTitleStart">Productos de Linea</label>
      <OdenFabricacionEnCursoCards
        ordenes={
          OrdenState?.ordenes?.filter((order) =>
            !order.codigoProducto.startsWith('R') && !order.codigoProducto.startsWith('r')
        ).filter((orden: Orden) =>
          !orden.finalizado &&
          (orden.producto?.codigo
            .toLowerCase()
            .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 ||
            orden.lote
            .toString()
            .toLowerCase()
            .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 ||
            orden.producto?.nombre
            .toLowerCase()
            .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 || 
            orden?.codigoCliente
            .toLowerCase()
            .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 )
            ? true
            : false
          ) || []
        }
      />

      <label className="ContentTitleStart">Colores</label>
      <OdenFabricacionEnCursoCards
        ordenes={
          OrdenState?.ordenes?.filter((order) =>
            order.codigoProducto.startsWith('R') || order.codigoProducto.startsWith('r')
          ).filter((orden: Orden) =>
            !orden.finalizado &&
            (orden.producto?.codigo
              .toLowerCase()
              .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 ||
              orden.lote
                .toString()
                .toLowerCase()
                .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 ||
              orden.producto?.nombre
                .toLowerCase()
                .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 || 
                orden?.codigoCliente
                .toLowerCase()
                .indexOf(OrdenEnCursoFilter.toLocaleLowerCase()) !== -1 )
              ? true
              : false
          ) || []
        }
      />

<div className='row col-md-12 d-flex'>
  <label className="SubContentTitle col-auto col-auto me-0 pe-0">
    Ordenes de fabricación finalizadas{' '}
  </label>

  <div className='col-md-3 align-items-center'> 
    <div className="input-group"> {/* Utiliza la clase input-group */}
      <input
        className='form-control col-md-6 me-1' // Ajusta el tamaño según tus necesidades
        type="text"
        id="SearchOrdenFinalized"
        placeholder="Buscar..."
        value={OrdenFinalizadaFilter}
        onKeyDown={pressEnterHandler}
        onChange={handleSearchText}
      />
      <button className="btn btn-light me-1 border" type="button" onClick={resetFilter}>x</button> {/* Botón de búsqueda */}
      <button className="btn btn-primary" type="button" onClick={pressSearchButtonHandler}>Buscar</button> {/* Botón de búsqueda */}
    </div>
    {flagLoteValidation && <small className="text-danger">Para la busqueda por <strong>Lote</strong> por favor ingrese un número válido.</small>}
  </div>
  
  {orderDateChecked && (
    <div className='col-md-2'> 
      <input
        className="form-control"
        id="OFDate"
        type="date"
        placeholder="dd/MM/yyyy"
        value={orderDate}
        onChange={OrderDateChangeHandler}
      />
    </div>
  )}
</div>


      <div className='mx-4'>
        {RadioButtons}
      </div>
      
      <div className="orderDetailsContainer">
        <OrdenFabricacionTableList
          Ordenes={
            OrdenState?.ordenes?.filter(x=> x.finalizado != null).sort((a, b) =>
              moment(b.fechaorden).valueOf() - moment(a.fechaorden).valueOf()
          ) || []

          // OrdenState?.ordenes?.filter((orden: Orden) =>
          //   orden.finalizado === true &&
          //   (orden.producto?.codigo
          //     .toLowerCase()
          //     .indexOf(OrdenFinalizadaFilter.toLocaleLowerCase()) !== -1 ||
          //     orden.lote
          //       .toString()
          //       .toLowerCase()
          //       .indexOf(OrdenFinalizadaFilter.toLocaleLowerCase()) !== -1 ||
          //     orden.producto?.nombre
          //       .toLowerCase()
          //       .indexOf(OrdenFinalizadaFilter.toLocaleLowerCase()) !== -1 || 
          //       orden.codigoCliente
          //       .toLowerCase()
          //       .indexOf(OrdenFinalizadaFilter.toLocaleLowerCase()) !== -1 )
          //     ? true
          //     : false
          // ) || []

          

          }
        />
      </div>

      <div className='orderDetailsContainer'>
        {OrdenState?.ordenes && <OrderPagination Pagination={
            {
                pageNumber: OrdenState?.pagination?.pageNumber || 0,
                pageSize: OrdenState?.pagination?.pageSize || 0,
                totalPages: OrdenState?.pagination?.totalPages || 0,
                totalRecords: OrdenState?.pagination?.totalRecords || 0
          }}
          onPageChange={handlePageChange}
           />}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...OrdenFabricacion.actionCreators
  // ...ProductosStore.getAllProductos
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdenesFabricacionPage as any);
